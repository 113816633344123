import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { getImageUrl, initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";

import Section from "../Section";
import Icon from "../Icon";
import { Reveal } from "react-reveal";

const identifier = "lpEbook";

const ebook = {
  title: "E-Book: Mitgliederbereiche planen, aufbauen und vermarkten",
  price: "EUR 12,99",
  priceText: "",
  buttonUrl: "https://www.digistore24.com/product/445369",
  buttonLabel: "Ja, ich möchte das E-Book haben!",
  buttonIcon: "rocket",
  buttonBelowText:
    "(Die Zahlungsabwicklung erfolgt über den Zahlungsdienstleister Digistore)",
};

function EbookLandingPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "ebook",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: {},
  });

  const [sections, setSections] = useState(null);

  const init = () => {
    initPage(page, (page) => {
      setPage({ ...page });
      const foundSections = {};
      if (page.parts.mainPageSpecialSections) {
        Object.keys(page.parts.mainPageSpecialSections).map((sectionKey) => {
          if (sectionKey.substring(0, 6) === "ebook.")
            foundSections[sectionKey] =
              page.parts.mainPageSpecialSections[sectionKey];
        });
        setSections(foundSections);
      }
    });
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  const { data } = page;

  return (
    <div className={`page main-page landingpage page-${page.identifier}`}>
      <MainLayout page={page}>
        <Section>
          <Reveal>
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={12} mdo={3}>
                  {data.PreTitle && (
                    <div className="page-pretitle">{data.PreTitle}</div>
                  )}
                  <h1>{data.Title}</h1>
                  <div
                    className="introtext"
                    dangerouslySetInnerHTML={{ __html: data.IntroText }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: data.Content }} />
                  {page.locale !== "de" && (
                    <div className="small warning callout">
                      <strong>Note:</strong> This ebook is only available in 🇩🇪
                      German right now.
                    </div>
                  )}
                </Cell>
                <Cell sm={24} md={6}>
                  {data.TitleImage && (
                    <img src={getImageUrl(data.TitleImage)} alt="" />
                  )}
                </Cell>
              </Row>
            </Grid>
          </Reveal>
        </Section>

        <Section style={{ backgroundColor: "rgb(240, 240, 240)" }}>
          <Reveal>
            <Grid>
              <Row>
                <Cell sm={24} md={12} mdo={6}>
                  <PageSection section={sections["ebook.overview"]} />
                  <BuyButton />
                </Cell>
              </Row>
            </Grid>
          </Reveal>
        </Section>
        <Section>
          <Reveal>
            <Grid>
              <Row>
                <Cell sm={24} md={16} mdo={4}>
                  <PageSection section={sections["ebook.contents"]} />
                  <BuyButton />
                </Cell>
              </Row>
            </Grid>
          </Reveal>
        </Section>
        <Section style={{ backgroundColor: "rgb(240, 240, 240)" }}>
          <Reveal>
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={14}>
                  <PageSection section={sections["ebook.about"]} />
                </Cell>
                <Cell sm={24} md={10}>
                  <div className="text-center">
                    <img
                      style={{ width: "60%", transform: "rotate(5deg)" }}
                      src={getImageUrl(sections["ebook.about"].Image)}
                      alt=""
                    />
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Reveal>
        </Section>
        <Reveal>
          <Section>
            <Grid>
              <Row>
                <Cell sm={24} md={16} mdo={4}>
                  <PageSection section={sections["ebook.faq"]} />
                </Cell>
              </Row>
            </Grid>
          </Section>
          <Section style={{ backgroundColor: "rgb(240, 240, 240)" }}>
            <BuyButton title={ebook.title} noPadding />
          </Section>
        </Reveal>
      </MainLayout>
    </div>
  );
}

const PageSection = ({ section }) => {
  return (
    <div className="__landingpage-section">
      <h2>{section.Title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: section.IntroText }}
        className="introtext"
      />
      <div
        dangerouslySetInnerHTML={{ __html: section.Content }}
        className="section-content"
      />
    </div>
  );
};

const BuyButton = ({ title, noPadding }) => (
  <div className={`__landingpage-buy-button ${noPadding ? "nopadding" : ""}`}>
    {title && <h3>{title}</h3>}
    <div className="__landingpage-buy-button-price">{ebook.price}</div>
    <div className="__landingpage-buy-button-price-text">{ebook.priceText}</div>
    <div className="__landingpage-buy-button-button">
      <a
        href={ebook.buttonUrl}
        target="_blank"
        className="large primary button"
      >
        <Icon icon={ebook.buttonIcon} left /> {ebook.buttonLabel}
      </a>
    </div>
    <div className="__landingpage-buy-button-button-below-text">
      {ebook.buttonBelowText}
    </div>
  </div>
);

export default EbookLandingPage;
